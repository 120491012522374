/// <reference path='../../../../../node_modules/@types/bootstrap/index.d.ts' />
/// <reference path='../../../../../node_modules/@types/jquery/index.d.ts' />
/// <reference path='../../../../../node_modules/@types/jqueryui/index.d.ts' />

var nowTemp = new Date();
var now = new Date(nowTemp.getFullYear(), nowTemp.getMonth(), nowTemp.getDate(), 0, 0, 0, 0);

function getQueryVariable(variable): string {
    var query = window.location.search.substring(1);
    var vars = query.split('&');
    for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split('=');
        if (decodeURIComponent(pair[0]) === variable) {
            return decodeURIComponent(pair[1]);
        }
    }
    return "";
}

$(document).ready(() => {
    $(".product-edit-collapse-btn").hide();
    $(".product-edit-narrative").hide();
    $('#new-promotion-code').hide();    

    $("#new-promotion-code").find("input").data("val", false);

    $('.product-image-carousel').carousel({
        interval: 0
    });

    $('.product-image-link').click(function () {
        $(this).siblings('.popup-image').first().dialog({
            modal: true,
            draggable: false,
            resizable: false,
            position: ['center', 'center'],
            show: 'fade',
            width: '100%',
            height: 'auto',
            hide: 'fade',
            close: function () {
                $(this).dialog("destroy");
            } 
        });

        $('.ui-widget-overlay').click(function () {
            $(this).siblings('.ui-dialog').children('.ui-dialog-content').first().dialog("close");
        });
        $('.ui-dialog-content').click(function () {
            $(this).dialog("close");
        });

        $('.ui-dialog-content').on('pinchclose', function () {
            $(this).dialog("close");
        });
    });
}); 

$(".product-edit-expand-btn").click(function () {
    var choiceCode = $(this).data("choice-code");
    var collapseTargetId = "#collapse-" + choiceCode;
    var detailsTargetId = "#details-" + choiceCode;

    $(collapseTargetId).show();
    $(detailsTargetId).show();
    $(this).hide();
});

$(".product-edit-collapse-btn").click(function () {
    var choiceCode = $(this).data("choice-code");
    var expandTargetId = "#expand-" + choiceCode;
    var detailsTargetId = "#details-" + choiceCode;

    $(expandTargetId).show();
    $(detailsTargetId).hide();
    $(this).hide();
});

$(".product-edit-add-btn").click(() => {
    $("#new-promotion-code").show();
    $('#new-promotion-code-button').hide();
    $("#new-promotion-code").find("input").data("val", "true");
    $('.has-new-choice').val("true");
});

$(".product-edit-cancel-btn").click(() => {
    $("#new-promotion-code-button").show();
    $('#new-promotion-code').hide();
    $("#new-promotion-code").find("input").data("val", "false");
    $('.has-new-choice').val("false");

});


$('.custom-form').each(function () {
    // populate variables from query string
    $(this).find('input, select, textarea').each(function () {
        var override = getQueryVariable($(this).attr('id'));

        if ($(this).is(':radio') || $(this).is(':checkbox')) {
            if ($(this).val() === override) {
                $(this).prop("checked", true);
            }
        }
        else {
            if (override) {
                $(this).val(override);
            }
        }
    });
});

$('input[type="number"]').on('keypress', function (event) {
    // prevent non-numeric values on numeric text input fields.
    if (event.which < 48 || event.which > 57) {
        // Allow a single '.' or ',' for decimal fields. A ',' is used in some cultures to represent a decimal.
        // Chrome seems a bit smarter than IE. If the culture uses ',' as a decimal, Chrome will allow a '.' or ',' to be entered and
        // will convert a ',' to a '.'
        // IE allows either character to be typed and doesn't do a conversion. Validation on the server will then catch invalid input.
        var textbox = $(this);
        var numberOfPeriods = textbox.val().split('.').length - 1;
        var numberOfCommas = textbox.val().split(',').length - 1;
        if (textbox.val() === '' ||
            textbox.attr('step') !== 'any' ||
            event.which !== 46 && event.which !== 44 ||
            numberOfPeriods + numberOfCommas > 0 ) {
            event.preventDefault();
        }
    }
    return true;
});

function showSpinnerAtDiv(divId) {
    $('#' + divId).addClass("csspinner ringed");
}

$('.print-button').click(() => {
    $('.navbar').hide();
    $('aside').hide();
    $('#featured').hide();
    $('#Footer_Content').hide();
    $('body.fixed-nav').addClass('hide-navigation');
    $('.print-button').hide();
    $('.quad').hide();
    $('.row.prime').addClass('hide-navigation');
     
    window.print();

    $('.navbar').show();
    $('aside').show();
    $('#featured').show();
    $('body.fixed-nav').removeClass('hide-navigation');
    $('.print-button').show();
    $('#Footer_Content').show();
    $('.quad').show();
    $('.row.prime').removeClass('hide-navigation');
});

// disable mousewheel on a input number field when in focus
// (to prevent Chromium browsers change the value when scrolling)
$('form').on('focus', 'input[type=number]', function () {
    $(this).on('mousewheel.disableScroll', e => {
        e.preventDefault();
    });
});
$('form').on('blur', 'input[type=number]', function () {
    $(this).off('mousewheel.disableScroll');
})